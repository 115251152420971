import { EventActions } from './index';
import { MaritimeEvent } from '../../types/event';
import {
  areBarrierEventsVisible,
  barrierEventMatchesFilters,
  EventPageSegments,
  getDateRangeFilterOptions,
  getDefaultDateRangeFilter,
  getTopConcerns,
  maritimeEventMatchesFilters
} from '../../reducers/eventReducer';
import apiClient from '../../api/api-client';
import { MainFunctionShortDto } from '../../types/equipment/mainFunctionDto';
import { EventFilter } from '../../types/event/eventFilter';
import { BarrierEventFilter } from '../../types/event/barrierEventFilter';
import { loadMainFunctions } from '../equipment/actions';
import { StreamDto } from '../../types/event/stream';
import { EventType } from '../../types/event/eventType';
import { EquipmentFindingStatuses } from '../../types/equipment/equipmentFindingStatus';
import { EventFilterQueryKeys } from '../../types/event/eventFilterQueryKeys';

const dateRangeOptionToDate = filter => {
  const date = new Date();
  switch (filter.unit) {
    case 'minute':
      date.setTime(date.getTime() + filter.amount * 60000);
      break;
    case 'hour':
      date.setTime(date.getTime() + filter.amount * 3600000);
      break;
    case 'day':
      date.setDate(date.getDate() + filter.amount);
      break;
    case 'year':
      date.setFullYear(date.getFullYear() + filter.amount);
      break;
  }
  return date;
};

export const getTimeFilters = dateRange => {
  if (dateRange === null) {
    return {};
  }
  if (dateRange.id !== 'custom') {
    return {
      from: dateRangeOptionToDate(dateRange)
    };
  }
  return {
    from: dateRange.from,
    to: dateRange.to
  };
};

const composeEventSearchFilter = (appliedFilters, locations): EventFilter => {
  const timeFilters = getTimeFilters(appliedFilters.dateRange);

  return {
    from: timeFilters.from,
    to: timeFilters.to,
    locationIds: appliedFilters.location ? [appliedFilters.location.globalId] : locations.map(r => r.globalId),
    ratings: [...appliedFilters.rating.map(r => r.id)],
    mainFunctions: [...appliedFilters.mainFunction.map(r => r.functionType)],
    equipmentFindingStatus: appliedFilters.equipmentFindingStatus ? appliedFilters.equipmentFindingStatus.id : null,
    eventTypes: [...appliedFilters.eventType.map(r => r.id)],
    processNames: [...appliedFilters.processName.map(r => r.id)]
  };
};

export const tryApplyEventFiltersFromUri = () => async (dispatch, getState) => {
  const state = getState();
  const { locations, mainFunctions, filters } = state.eventReducer;
  const topConcerns = getTopConcerns(state);
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.size === 0) {
    return;
  }

  const parsedFilters = {};

  const setParsedFilter = (filterKey: string, value: object | object[], comparatorKey = 'id') => {
    if (!parsedFilters[filterKey]) {
      parsedFilters[filterKey] = value;
    } else if (Array.isArray(parsedFilters[filterKey]) && Array.isArray(value)) {
      for (const arrValue of value) {
        if (!parsedFilters[filterKey].find(r => r[comparatorKey] === arrValue[comparatorKey])) {
          parsedFilters[filterKey].push(arrValue);
        }
      }
    }
  };

  const trySetFilterOptionByQueryValue = (filterKey: string, filterOptions: object[], value: string, comparatorKey = 'id', isArrayValue = false) => {
    const filterOption = filterOptions.find(filter => filter[comparatorKey].toString().toLowerCase() === value.toLowerCase());
    if (!filterOption) {
      return;
    }

    setParsedFilter(filterKey, isArrayValue ? [filterOption] : filterOption, comparatorKey);
  };

  for (const [key, value] of searchParams.entries()) {
    switch (key) {
      case EventFilterQueryKeys.From: {
        const dateRangeFilterOption = tryParseDateRangeFilterFromQuery(value, searchParams.get('to'));
        setParsedFilter('dateRange', dateRangeFilterOption);
        break;
      }
      case EventFilterQueryKeys.Last: {
        trySetFilterOptionByQueryValue('dateRange', getDateRangeFilterOptions(), value, 'uriKey');
        break;
      }
      case EventFilterQueryKeys.LocationId: {
        trySetFilterOptionByQueryValue('location', locations, value, 'globalId');
        break;
      }
      case EventFilterQueryKeys.MainFunction: {
        trySetFilterOptionByQueryValue('mainFunction', mainFunctions, value, 'functionType', true);
        break;
      }
      case EventFilterQueryKeys.FindingStatus: {
        trySetFilterOptionByQueryValue('equipmentFindingStatus', filters.equipmentFindingStatus, value);
        break;
      }

      case EventFilterQueryKeys.Rating: {
        trySetFilterOptionByQueryValue('rating', filters.rating, value, 'id', true);
        break;
      }

      case EventFilterQueryKeys.RiskModel: {
        trySetFilterOptionByQueryValue('topConcern', topConcerns, value, 'code', true);
        break;
      }
      case EventFilterQueryKeys.EventType:
      case EventFilterQueryKeys.Process: {
        const option = { id: value, label: value };
        setParsedFilter(key, [option]);
        break;
      }
      default:
        break;
    }
  }

  // set empty query string if no correct filters were found
  if (Object.keys(parsedFilters).length === 0) {
    const url = new URL(window.location.origin + window.location.pathname);
    window.history.replaceState({}, '', url.toString());
    return;
  }

  if (!parsedFilters['dateRange']) {
    parsedFilters['dateRange'] = getDefaultDateRangeFilter();
  }

  if (parsedFilters['topConcern'] && parsedFilters['topConcern'].length && parsedFilters['rating'] && parsedFilters['rating'].length) {
    parsedFilters['rating'] = parsedFilters['rating'].filter(r => filters.allowedBarrierRatingFilters.includes(r.id));
  }

  await dispatch(updateAppliedFilters(parsedFilters, true));
};

const tryParseDateRangeFilterFromQuery = (from: string, to: string): object => {
  const fromDate = new Date(from);
  if (isNaN(fromDate.getTime())) {
    return null;
  }

  const toDate = new Date(Date.parse(to));
  if (isNaN(toDate.getTime()) || fromDate > toDate || toDate > new Date()) {
    return null;
  }

  return {
    id: 'custom',
    label: 'Custom',
    from: fromDate.toISOString(),
    to: toDate.toISOString()
  };
};

export const handleEventAddedOrUpdatedCallback = (event: MaritimeEvent) => async (dispatch, getState) => {
  const state = getState();
  const barrierEventsVisible = areBarrierEventsVisible(state);
  const isBarrierEvent = !!event.barrier;
  if (!barrierEventsVisible && !isBarrierEvent) {
    if (maritimeEventMatchesFilters(event, state.eventReducer.appliedFilters)) {
      if (state.eventReducer.events.find(r => r.eventId === event.eventId)) {
        return await dispatch(updateEvent(event));
      }

      await dispatch(addEvent(event));
    }
  } else if (barrierEventsVisible && isBarrierEvent) {
    if (barrierEventMatchesFilters(event, state.eventReducer.appliedFilters)) {
      await dispatch(updateBarrierEvent(event));
    }
  }
};

export function loadEvents() {
  return async (dispatch, getState) => {
    dispatch(loadEventsBegin());
    try {
      const { appliedFilters, locations } = getState().eventReducer;
      const eventFilter = composeEventSearchFilter(appliedFilters, locations);
      const events = await apiClient.eventApi.getEventsByFilter(eventFilter);
      handleErrors(events);
      const filters = composeFiltersFromEvents(events);
      dispatch(loadEventsSuccess(events, filters));
      return events;
    } catch (error) {
      dispatch(loadEventsFailure(error));
    }
  };
}

export function exportEventsAsCsv() {
  return async (_, getState) => {
    const { appliedFilters, locations } = getState().eventReducer;
    const eventFilter = composeEventSearchFilter(appliedFilters, locations);
    await apiClient.eventApi.downloadEventsAsCsv(eventFilter);
  };
}

export function loadEvent(eventId) {
  return async (dispatch, getState) => {
    try {
      const event = await apiClient.eventApi.getEvent(eventId);
      handleErrors(event);
      const filters = composeFiltersFromEvents([...getState().eventReducer.events, event]);
      await dispatch(loadEventSuccess(event, filters));
      return event;
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateAppliedFilters(newAppliedFilters, replaceFilters = false) {
  return async (dispatch, getState) => {
    if (replaceFilters) {
      await dispatch(replaceAppliedFilters(newAppliedFilters));
    } else {
      await dispatch(setAppliedFilters(newAppliedFilters));
    }
    const { appliedFilters, eventPageSegment } = getState().eventReducer;
    if (eventPageSegment === EventPageSegments.Equipment) {
      await dispatch(loadMainFunctions());
      return;
    }

    if (appliedFilters.topConcern.length) {
      if (Object.keys(newAppliedFilters).some(key => ['topConcern', 'location', 'rating'].includes(key))) {
        await dispatch(loadBarrierEvents());
      }
    } else {
      if (
        Object.keys(newAppliedFilters).some(key =>
          ['dateRange', 'location', 'rating', 'topConcern', 'mainFunction', 'equipmentFindingStatus'].includes(key)
        )
      ) {
        await dispatch(loadEvents());
      }
    }
    updateEventFiltersInUri(appliedFilters);
  };
}

const updateEventFiltersInUri = appliedFilters => {
  const url = new URL(window.location.origin + window.location.pathname);
  if (appliedFilters.dateRange.uriKey) {
    url.searchParams.append(EventFilterQueryKeys.Last, appliedFilters.dateRange.uriKey);
  } else {
    url.searchParams.append(EventFilterQueryKeys.From, appliedFilters.dateRange.from);
    url.searchParams.append(EventFilterQueryKeys.To, appliedFilters.dateRange.to);
  }

  const appendFilterToSearchParams = (queryKey: string, value: any, propertyKey = 'id') => {
    if (Array.isArray(value) && value.length > 0) {
      for (const arrValue of value) {
        url.searchParams.append(queryKey, arrValue[propertyKey]);
      }
      return;
    }

    if (!Array.isArray(value) && !!value) {
      url.searchParams.append(queryKey, value[propertyKey]);
    }
  };

  appendFilterToSearchParams(EventFilterQueryKeys.LocationId, appliedFilters.location, 'globalId');
  appendFilterToSearchParams(EventFilterQueryKeys.MainFunction, appliedFilters.mainFunction, 'functionType');
  appendFilterToSearchParams(EventFilterQueryKeys.FindingStatus, appliedFilters.equipmentFindingStatus);
  appendFilterToSearchParams(EventFilterQueryKeys.RiskModel, appliedFilters.topConcern, 'code');
  appendFilterToSearchParams(EventFilterQueryKeys.Rating, appliedFilters.rating);
  appendFilterToSearchParams(EventFilterQueryKeys.EventType, appliedFilters.eventType);
  appendFilterToSearchParams(EventFilterQueryKeys.Process, appliedFilters.processName);

  window.history.pushState({}, '', url.toString());
};

export function loadActions() {
  return async dispatch => {
    try {
      const actions = await apiClient.actionApi.getAllActions();
      dispatch(loadActionsSuccess(actions));
    } catch (ex) {
      console.log('Actions fetching failed', ex);
    }
  };
}

export const loadBarriers = () => async dispatch => {
  try {
    const barriers = await apiClient.barrierApi.getAllBarriers();
    dispatch(setBarriers(barriers));
  } catch (ex) {
    console.log('Fetching barriers failed', ex);
  }
};

const loadBarrierEvents = () => async (dispatch, getState) => {
  try {
    const { appliedFilters, locations, filters } = getState().eventReducer;
    const barrierFilter = {
      topConcernCodes: [...appliedFilters.topConcern.map(r => r.code)],
      locationIds: appliedFilters.location ? [appliedFilters.location.globalId] : locations.map(r => r.globalId)
    } as BarrierEventFilter;

    const selectedAllowedRatings = appliedFilters.rating.map(r => r.id).filter(r => filters.allowedBarrierRatingFilters.includes(r));

    barrierFilter.ratings = selectedAllowedRatings.length ? selectedAllowedRatings : [...filters.allowedBarrierRatingFilters];

    const barrierEvents = await apiClient.eventApi.getBarrierEventsByFilter(barrierFilter);
    await dispatch(setBarrierEvents(barrierEvents));
  } catch (ex) {
    console.log('Fetching barrier events failed', ex);
  }
};

export function getEventRelatedActions(event: MaritimeEvent, stream: StreamDto) {
  return (_, getState) => {
    const eventRelatedActions = getState().eventReducer.actions.filter(action => {
      let matchesFilter = true;
      const actionProperties = action.relatedTo;
      if (actionProperties.eventType.length) {
        matchesFilter &&= actionProperties.eventType.includes(event.eventType);
      }

      if (actionProperties.mcKey.length) {
        matchesFilter &&= actionProperties.mcKey.includes(event.eventKey);
      }

      if (actionProperties.rating.length) {
        matchesFilter &&= actionProperties.rating.includes(event.rating);
      }

      if (actionProperties.equipmentType.length) {
        matchesFilter &&= actionProperties.equipmentType.some(equipmentType => {
          if (!event.equipmentKey) {
            return false;
          }

          const exprToMatch = equipmentType === '*' ? '.*' : equipmentType;
          return !!event.equipmentKey.match(exprToMatch);
        });
      }

      if (actionProperties.findingStatus) {
        if (![EventType.EquipmentFinding, EventType.EquipmentCloseFinding].includes(event.eventType)) {
          return false;
        }

        if (actionProperties.findingStatus === EquipmentFindingStatuses.Closed) {
          matchesFilter &&= event.eventType === EventType.EquipmentCloseFinding;
        } else {
          matchesFilter &&=
            event.eventType === EventType.EquipmentFinding && stream.events.every(ev => ev.eventType !== EventType.EquipmentCloseFinding);
        }
      }

      return matchesFilter;
    });

    return eventRelatedActions || [];
  };
}

export async function loadStream(streamId) {
  const stream = await apiClient.eventApi.getStream(streamId);
  return stream;
}

export async function requestAction(eventId, actionId) {
  const actionRequestDto = { eventId, actionId };
  const eventAction = await apiClient.eventApi.requestAction(actionRequestDto);
  return eventAction;
}

export const getComposedFilterKeys = () => ['processName', 'eventType'];

export function composeFiltersFromEvents(events) {
  const filterKeys = getComposedFilterKeys();
  const distinctFiltersValues = filterKeys.reduce((filters, key) => {
    filters[key] = [];
    return filters;
  }, {});

  events.reduce((filters, event) => {
    for (const filterKey of filterKeys) {
      const value = event[filterKey];
      if (value && !filters[filterKey].includes(value)) {
        filters[filterKey].push(value);
      }
    }
    return filters;
  }, distinctFiltersValues);

  return filterKeys.reduce((filters, filterKey) => {
    filters[filterKey] = distinctFiltersValues[filterKey].map(r => ({ id: r, label: r })) || [];
    return filters;
  }, {});
}

export function loadLocations() {
  return async dispatch => {
    dispatch(loadLocationsBegin());
    try {
      const locations = await apiClient.locationApi.loadLocations();
      const editedLocations = locations.map(l => ({ ...l, globalId: l.globalId.replace('IMO:', '') }));
      dispatch(loadLocationsSuccess(editedLocations));
      return locations;
    } catch (error) {
      dispatch(loadLocationsFailure(error));
    }
  };
}

export function loadVesselPositions() {
  return async (dispatch, getState) => {
    const globalIds = getState().eventReducer.locations.map(r => r.globalId);
    dispatch(loadVesselPositionsBegin());
    try {
      const response = await apiClient.vesselPositionApi.getVesselPositions({ globalIds });
      dispatch(loadVesselPositionsSuccess(response));
    } catch (err) {
      console.log('Failed to load vessel positions', err);
    }
  };
}

export function loadMainFunctionOptions() {
  return async dispatch => {
    try {
      const mainFunctionShortList = await apiClient.equipmentApi.getAllMainFunctionsShort();
      dispatch(setMainFunctionOptions(mainFunctionShortList));
    } catch (err) {
      console.log('Failed to load main function options', err);
    }
  };
}

export interface SetMainFunctionOptions {
  type: EventActions.SET_MAIN_FUNCTION_OPTIONS;
  payload: any;
}

export const setMainFunctionOptions = (mainFunctionShortList: MainFunctionShortDto[]) => async dispatch =>
  dispatch({
    type: EventActions.SET_MAIN_FUNCTION_OPTIONS,
    payload: { mainFunctionShortList }
  });

export function resetAppliedFiltersAndReload() {
  return async dispatch => {
    dispatch(resetAppliedFilters());
    dispatch(loadEvents());
  };
}

export function markEventReadForUser(event) {
  return async dispatch => {
    const splitDate = event.timeUtc.split('-');
    const eventReadDto = {
      eventId: event.eventId,
      dateHash: Number(splitDate[0] + splitDate[1])
    };
    const result = await apiClient.eventApi.setEventRead(eventReadDto);
    if (result.isValid) {
      dispatch(markEventRead(event.eventId));
    }
  };
}

function handleErrors(response) {
  if (!response) {
    throw new Error(response.statusText);
  }
  return response;
}

// ACTIONS - should these be separated?

export interface LoadEventsBeginAction {
  type: EventActions.LOAD_EVENTS_BEGIN;
}

export const loadEventsBegin = () => async dispatch =>
  dispatch({
    type: EventActions.LOAD_EVENTS_BEGIN
  });

export interface LoadEventsSuccessAction {
  type: EventActions.LOAD_EVENTS_SUCCESS;
  payload: any;
}

export const loadEventsSuccess = (events, filters) => async dispatch =>
  dispatch({
    type: EventActions.LOAD_EVENTS_SUCCESS,
    payload: { events, filters }
  });

export interface LoadEventSuccessAction {
  type: EventActions.LOAD_EVENT_SUCCESS;
  payload: any;
}

export const loadEventSuccess = (event, filters) => async dispatch =>
  dispatch({
    type: EventActions.LOAD_EVENT_SUCCESS,
    payload: { event, filters }
  });

export interface LoadEventsFailureAction {
  type: EventActions.LOAD_EVENTS_FAILURE;
  payload: { error };
}

export const loadEventsFailure = error => async dispatch =>
  dispatch({
    type: EventActions.LOAD_EVENTS_FAILURE,
    payload: { error }
  });

export interface AddEventAction {
  type: EventActions.ADD_EVENT;
  payload: any;
}

export const addEvent = event => async dispatch =>
  dispatch({
    type: EventActions.ADD_EVENT,
    payload: { event }
  });

export interface UpdateEventAction {
  type: EventActions.UPDATE_EVENT;
  payload: any;
}

export const updateEvent = event => async dispatch =>
  dispatch({
    type: EventActions.UPDATE_EVENT,
    payload: { event }
  });

export interface LoadActionSuccessAction {
  type: EventActions.LOAD_ACTIONS_SUCCESS;
  payload: any;
}

export const loadActionsSuccess = actions => dispatch =>
  dispatch({
    type: EventActions.LOAD_ACTIONS_SUCCESS,
    payload: { actions }
  });

export interface LoadLocationsBeginAction {
  type: EventActions.LOAD_LOCATIONS_BEGIN;
}

export const loadLocationsBegin = () => async dispatch =>
  dispatch({
    type: EventActions.LOAD_LOCATIONS_BEGIN
  });

export interface LoadLocationsSuccessAction {
  type: EventActions.LOAD_LOCATIONS_SUCCESS;
  payload: any;
}

export const loadLocationsSuccess = locations => async dispatch =>
  dispatch({
    type: EventActions.LOAD_LOCATIONS_SUCCESS,
    payload: { locations }
  });

export interface LoadLocationsFailureAction {
  type: EventActions.LOAD_LOCATIONS_FAILURE;
  payload: { error };
}

export const loadLocationsFailure = error => async dispatch =>
  dispatch({
    type: EventActions.LOAD_LOCATIONS_FAILURE,
    payload: { error }
  });

export interface LoadVesselPositionsBeginAction {
  type: EventActions.LOAD_VESSEL_POSITIONS_BEGIN;
}

export const loadVesselPositionsBegin = () => async dispatch =>
  dispatch({
    type: EventActions.LOAD_VESSEL_POSITIONS_BEGIN
  });

export interface LoadVesselPositionsSuccessAction {
  type: EventActions.LOAD_VESSEL_POSITIONS_SUCCESS;
  payload: any;
}

export const loadVesselPositionsSuccess = vesselPositions => async dispatch =>
  dispatch({
    type: EventActions.LOAD_VESSEL_POSITIONS_SUCCESS,
    payload: { vesselPositions }
  });

export interface UpdateVesselPositionAction {
  type: EventActions.UPDATE_VESSEL_POSITION;
  payload: any;
}

export const handleVesselPositionUpdateCallback = vesselPosition => async (dispatch, getState) => {
  const state = getState();
  if (!state.eventReducer.locations.find(r => r.globalId === vesselPosition.globalId)) {
    return;
  }

  dispatch({
    type: EventActions.UPDATE_VESSEL_POSITION,
    payload: { vesselPosition }
  });
};

export const handleVesselPositionDeletedCallback = globalId => async dispatch =>
  dispatch({
    type: EventActions.DELETE_VESSEL_POSITION,
    payload: { globalId }
  });

export interface DeleteVesselPositionAction {
  type: EventActions.DELETE_VESSEL_POSITION;
  payload: any;
}

export const deleteVesselPosition = globalId => async dispatch =>
  dispatch({
    type: EventActions.DELETE_VESSEL_POSITION,
    payload: { globalId }
  });

export interface ToggleShowMapAction {
  type: EventActions.TOGGLE_SHOW_MAP;
}

export const toggleShowMap = () => async dispatch =>
  dispatch({
    type: EventActions.TOGGLE_SHOW_MAP
  });

export interface SetShowMapAction {
  type: EventActions.SET_SHOW_MAP;
  payload: any;
}

export const setShowMap = showMap => async dispatch =>
  dispatch({
    type: EventActions.SET_SHOW_MAP,
    payload: { showMap }
  });

export interface ToggleShowTrendsAction {
  type: EventActions.TOGGLE_SHOW_TRENDS;
}

export const toggleShowTrends = () => async dispatch =>
  dispatch({
    type: EventActions.TOGGLE_SHOW_TRENDS
  });

export interface SetShowAllLocationsQuickOption {
  type: EventActions.SET_SHOW_ALL_LOCATIONS_QUICK_OPTION;
  payload: any;
}

export const setShowAllLocationsQuickOption = (showAllLocationsQuickOption: boolean) => async dispatch =>
  dispatch({
    type: EventActions.SET_SHOW_ALL_LOCATIONS_QUICK_OPTION,
    payload: { showAllLocationsQuickOption }
  });

export interface ResetAppliedFiltersAction {
  type: EventActions.RESET_APPLIED_FILTERS;
}

export const resetAppliedFilters = () => async dispatch =>
  dispatch({
    type: EventActions.RESET_APPLIED_FILTERS
  });

export interface SetAppliedFiltersAction {
  type: EventActions.SET_APPLIED_FILTERS;
  payload: any;
}

export const setAppliedFilters = appliedFilters => async dispatch =>
  dispatch({
    type: EventActions.SET_APPLIED_FILTERS,
    payload: { appliedFilters }
  });

export interface ReplaceAppliedFiltersAction {
  type: EventActions.REPLACE_APPLIED_FILTERS;
  payload: any;
}

export const replaceAppliedFilters = appliedFilters => async dispatch =>
  dispatch({
    type: EventActions.REPLACE_APPLIED_FILTERS,
    payload: { appliedFilters }
  });

export interface MarkEventReadAction {
  type: EventActions.MARK_EVENT_READ;
  payload: any;
}

export const markEventRead = eventId => async dispatch =>
  dispatch({
    type: EventActions.MARK_EVENT_READ,
    payload: { eventId }
  });

export interface SetTrendsPeriodAction {
  type: EventActions.SET_TRENDS_PERIOD;
  payload: any;
}

export const setTrendsPeriod = period => async dispatch =>
  dispatch({
    type: EventActions.SET_TRENDS_PERIOD,
    payload: { period }
  });

export interface SetBarriersAction {
  type: EventActions.SET_BARRIERS;
  payload: any;
}

export const setBarriers = barriers => dispatch =>
  dispatch({
    type: EventActions.SET_BARRIERS,
    payload: { barriers }
  });

export interface SetBarrierEventsAction {
	type: EventActions.SET_BARRIER_EVENTS;
	payload: any;
}

export const setBarrierEvents = barrierEvents => dispatch =>
	dispatch({
		type: EventActions.SET_BARRIER_EVENTS,
		payload: {barrierEvents}
	});

export interface UpdateBarrierEventAction {
	type: EventActions.UPDATE_BARRIER_EVENT;
	payload: any;
}

export const updateBarrierEvent = barrierEvent => dispatch =>
	dispatch({
		type: EventActions.UPDATE_BARRIER_EVENT,
		payload: {barrierEvent}
	});

export interface SetEventPageSegmentAction {
	type: EventActions.SET_EVENT_PAGE_SEGMENT;
	payload: any;
}

export const setEventPageSegment = eventPageSegment => dispatch =>
	dispatch({
		type: EventActions.SET_EVENT_PAGE_SEGMENT,
		payload: {eventPageSegment}
	});
