import { BaseBarrierObject } from './baseBarrierObject';

export interface Barrier extends BaseBarrierObject {
  topConcerns: TopConcern[];
}

export interface TopConcern extends BaseBarrierObject {
  visibility: TopConcernVisibility;
  functions: BarrierFunction[];
}

export interface BarrierFunction extends BaseBarrierObject {
  type: number;
  elements: BarrierElement[];
}

export interface BarrierElement extends BaseBarrierObject {
  category: string;
}

export enum TopConcernVisibility {
  Public = 'Public',
  Internal = 'Internal'
}
