import { AuthActions } from './index';
import { startHubConnection, stopHubConnection } from '../signalr/actions';
import { processURLAddress } from '../../helpers/urlHelper';
import { checkPushNotificationPermission } from '../../services/pushNotificationsService';
import { StatisticsService } from '../../services/statisticsService';
import StatisticsEntryEvent from '../../types/statistics/statisticsEntryEvent';
import apiClient from '../../api/api-client';

export function startAuth() {
  return async dispatch => {
    const authExcludedPaths = ['/errors'];
    if (authExcludedPaths.some(path => window.location.pathname.startsWith(path))) {
      return;
    }

    const user = await getUser();
    if (user) {
      await dispatch(signInUserSuccess(user));
    }
  };
}

export function signInCallback() {
  return async dispatch => {
    await dispatch(startAuth());
  };
}

async function getUser() {
  return await apiClient.authApi.getUser();
}

export function signOut() {
  return async dispatch => {
    await dispatch(stopHubConnection());
    await dispatch(setUser(null));
    let logoutUrl = processURLAddress('/auth/logout');
    const queryParams = new URLSearchParams([['redirectUrl', window.location.origin + '/logout']]);
    logoutUrl += `?${queryParams.toString()}`;
    window.location.href = logoutUrl;
  };
}

export function signInUserSuccess(user) {
  return async dispatch => {
    await dispatch(setUser(user));
    await dispatch(startHubConnection());
    checkPushNotificationPermission();
    StatisticsService.addEntry({
      eventDate: new Date(),
      eventName: StatisticsEntryEvent.Login
    });
  };
}

export interface SetUserAction {
  type: AuthActions.SET_USER;
  payload: any;
}

export const setUser = user => async dispatch =>
  dispatch({
    type: AuthActions.SET_USER,
    payload: { user }
  });
