import { GeoZoneActions } from './index';
import apiClient from '../../api/api-client';
import { GeoZoneDto } from '../../types/geoZones/geoZone';

const mapGeoZones = (geoZones: GeoZoneDto[]) =>
  geoZones.map(r => ({
    ...r.geoJson,
    properties: {
      code: r.code,
      ...r.geoJson.properties
    }
  }));

export function loadGeoZones() {
  return async dispatch => {
    dispatch(loadGeoZonesBegin());

    try {
      const response = await apiClient.geoZoneApi.loadAllGeoZones();
      handleErrors(response);
      const geoZones = mapGeoZones(response);
      dispatch(loadGeoZonesSuccess(geoZones));
      return geoZones;
    } catch (error) {
      dispatch(loadGeoZonesFailure(error));
    }
  };
}

export const handleGeoZoneUpdateCallback = geoZone => async dispatch => {
  if (geoZone !== null) {
    const geoZones = mapGeoZones([geoZone]);
    await dispatch(updateGeoZone(geoZones[0]));
  } else {
    await dispatch(loadGeoZones());
  }
};

function handleErrors(response) {
  if (!response) {
    throw new Error(response.statusText);
  }
  return response;
}

export interface LoadGeoZonesBeginAction {
  type: GeoZoneActions.LOAD_GEO_ZONES_BEGIN;
}

export const loadGeoZonesBegin = () => async dispatch =>
  dispatch({
    type: GeoZoneActions.LOAD_GEO_ZONES_BEGIN
  });

export interface LoadGeoZonesSuccessAction {
  type: GeoZoneActions.LOAD_GEO_ZONES_SUCCESS;
  payload: any;
}

export const loadGeoZonesSuccess = geoZones => async dispatch =>
  dispatch({
    type: GeoZoneActions.LOAD_GEO_ZONES_SUCCESS,
    payload: { geoZones }
  });

export interface LoadGeoZonesFailureAction {
  type: GeoZoneActions.LOAD_GEO_ZONES_FAILURE;
  payload: { error };
}

export const loadGeoZonesFailure = error => async dispatch =>
  dispatch({
    type: GeoZoneActions.LOAD_GEO_ZONES_FAILURE,
    payload: { error }
  });

export interface UpdateGeoZoneAction {
  type: GeoZoneActions.UPDATE_GEO_ZONE;
  payload: any;
}

export const updateGeoZone = geoZone => async dispatch =>
  dispatch({
    type: GeoZoneActions.UPDATE_GEO_ZONE,
    payload: { geoZone }
  });
