const StatisticsEntryEvent = {
  Login: 'Login',
  TourStarted: 'TourStarted',
  TourCompleted: 'TourCompleted',
  LiveFeedView: 'LiveFeedView',
  MapView: 'MapView',
  EventDetailsView: 'EventDetailsView',
  MainFunctionView: 'MainFunctionView',
  MainFunctionDetailView: 'MainFunctionDetailView'
};

export default StatisticsEntryEvent;
