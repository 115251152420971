import apiClient from '../api/api-client';

export const checkPushNotificationPermission = () => {
  if ('Notification' in window && 'serviceWorker' in navigator) {
    Notification.requestPermission().then(permission => {
      console.log('permission', permission);

      if (permission !== 'granted') {
        console.log('User did not grant permission for push notifications');
        return;
      }

      checkPushNotificationSubscription();
    });
  }
};

const checkPushNotificationSubscription = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.pushManager.getSubscription().then(async subscription => {
        const vapidKeyResult = await apiClient.notificationApi.getVapidKey();
        const vapidKey = vapidKeyResult.result;

        if (!subscription) {
          createSubscriptionAndRegister(registration, vapidKey);
        }

        // check if subscription is valid for our vapid key
        console.log(subscription);
      });
    });
  }
};

const createSubscriptionAndRegister = (registration: ServiceWorkerRegistration, vapidKey: string) => {
  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: vapidKey
  } as PushSubscriptionOptionsInit;

  console.log('subscriptionOptions', subscribeOptions);

  registration.pushManager.subscribe(subscribeOptions).then(async pushSubscription => {
    const subscriptionJson = JSON.parse(JSON.stringify(pushSubscription));
    const subscriptionDto = {
      endpoint: subscriptionJson.endpoint,
      publicKey: subscriptionJson.keys.p256dh,
      authKey: subscriptionJson.keys.auth,
      applicationServerKey: vapidKey
    };

    const result = await apiClient.notificationApi.registerSubscription(subscriptionDto);
    console.log('result', result);
  });
};
