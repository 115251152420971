import StatisticsEntry from '../types/statistics/statisticsEntry';
import apiClient from '../api/api-client';

const addEntryFunc = async (entry: StatisticsEntry): Promise<boolean> => {
  const response = await apiClient.statisticsApi.addEntry(entry);
  return !!response && response.isValid;
};

export const StatisticsService = {
  addEntry: addEntryFunc
};
