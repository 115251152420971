import { LoadGeoZonesBeginAction, LoadGeoZonesSuccessAction, LoadGeoZonesFailureAction, UpdateGeoZoneAction } from './actions';

export type GeoZoneActionTypes = LoadGeoZonesBeginAction | LoadGeoZonesSuccessAction | LoadGeoZonesFailureAction | UpdateGeoZoneAction;

export enum GeoZoneActions {
  LOAD_GEO_ZONES_BEGIN = 'LOAD_GEO_ZONES_BEGIN',
  LOAD_GEO_ZONES_SUCCESS = 'LOAD_GEO_ZONES_SUCCESS',
  LOAD_GEO_ZONES_FAILURE = 'LOAD_GEO_ZONES_FAILURE',
  UPDATE_GEO_ZONE = 'UPDATE_GEO_ZONE'
}
