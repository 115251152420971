export const EventFilterQueryKeys = {
  Last: 'last',
  From: 'from',
  To: 'to',
  LocationId: 'locationId',
  MainFunction: 'mainFunction',
  FindingStatus: 'findingStatus',
  Rating: 'rating',
  RiskModel: 'riskModel',
  EventType: 'eventType',
  Process: 'processName'
};
