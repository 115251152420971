import { EquipmentActions } from './index';
import { ExtendedMainFunctionDto } from '../../types/equipment/mainFunctionDto';
import apiClient from '../../api/api-client';

export function loadMainFunctions() {
  return async (dispatch, getState) => {
    const currentLocation = getState().eventReducer.appliedFilters.location;
    const mainFunctions = await apiClient.equipmentApi.getLocationExtendedMainFunctions(currentLocation.globalId);
    await dispatch(setMainFunctions(mainFunctions));
  };
}

export interface SetMainFunctions {
  type: EquipmentActions.SET_MAIN_FUNCTIONS;
  payload: any;
}

export const setMainFunctions = (mainFunctions: ExtendedMainFunctionDto[]) => async dispatch =>
  dispatch({
    type: EquipmentActions.SET_MAIN_FUNCTIONS,
    payload: { mainFunctions }
  });
