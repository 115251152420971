import { SignalrActions } from './index';
import { processURLAddress } from '../../helpers/urlHelper';
import { handleEventAddedOrUpdatedCallback, handleVesselPositionDeletedCallback, handleVesselPositionUpdateCallback } from '../event/actions';
import { handleGeoZoneUpdateCallback } from '../geoZone/actions';

export function startHubConnection() {
  return async (dispatch, getState) => {
    if (getState().signalrReducer.hubConnection) {
      return;
    }

    const endpoint = processURLAddress('/cb', true);
    dispatch(setHubConnection(endpoint));
    const connection = getState().signalrReducer.hubConnection;

    connection.on('EventAddedOrUpdatedCallback', payload => {
      dispatch(handleEventAddedOrUpdatedCallback(payload));
    });

    connection.on('VesselPositionUpdatedCallback', payload => {
      dispatch(handleVesselPositionUpdateCallback(payload));
    });

    connection.on('VesselPositionDeletedCallback', payload => {
      dispatch(handleVesselPositionDeletedCallback(payload));
    });

    connection.on('GeoZoneUpdatedCallback', payload => {
      dispatch(handleGeoZoneUpdateCallback(payload));
    });

    let startedPromise = null;
    function start() {
      startedPromise = connection.start().catch(err => {
        console.error('Error establishing feed connection', err);
        return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
      });
      return startedPromise;
    }
    connection.onclose(() => start());
    start();
  };
}

export function stopHubConnection() {
  return async (dispatch, getState) => {
    const hubConnection = getState().signalrReducer.hubConnection;
    if (!hubConnection) {
      return;
    }
    await hubConnection.stop();
    dispatch(resetConnection());
  };
}

export interface SetHubConnectionAction {
  type: SignalrActions.SET_HUB_CONNECTION;
  payload: any;
}

export const setHubConnection = url => async dispatch =>
  dispatch({
    type: SignalrActions.SET_HUB_CONNECTION,
    payload: { url }
  });

export interface ResetConnectionAction {
  type: SignalrActions.RESET_CONNECTION;
}

export const resetConnection = () => async dispatch =>
  dispatch({
    type: SignalrActions.RESET_CONNECTION
  });
