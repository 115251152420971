import { EventActions, EventActionTypes } from '../actions/event';
import { Rating } from '../types/event/rating';
import { MaritimeEvent } from '../types/event';
import { Barrier, TopConcern, TopConcernVisibility } from '../types/barrier';
import { composeFiltersFromEvents, getComposedFilterKeys, getTimeFilters } from '../actions/event/actions';
import { VesselPositionDto } from '../types/vesselPosition/vesselPosition';
import { ActionDto } from '../types/actions/action';
import { MainFunctionShortDto } from '../types/equipment/mainFunctionDto';
import { EquipmentFindingStatuses } from '../types/equipment/equipmentFindingStatus';

interface EventState {
  events: MaritimeEvent[];
  barrierEvents: MaritimeEvent[];
  actions: ActionDto[];
  locations: any[];
  showAllLocationsQuickOption: boolean;
  vesselPositions: VesselPositionDto[];
  barriers: Barrier[];
  mainFunctions: MainFunctionShortDto[];
  eventPageSegment: string;
  appliedFilters: {
    dateRange: any;
    location: any;
    rating: any[];
    topConcern: TopConcern[];
    processName: any[];
    eventType: any[];
    mainFunction: any[];
    equipmentFindingStatus: any;
  };
  filters: {
    allowedBarrierRatingFilters: number[];
    rating: any[];
    equipmentFindingStatus: any[];
    processName: any[];
    eventType: any[];
  };
  trends: {
    currentPeriod: string;
    periodOptions: string[];
  };
  showMap: boolean;
  showTrends: boolean;
  loading: boolean;
}

const getAllowedBarrierViewRatingFilters = () => [Rating.WARNING, Rating.CRITICAL];

export const getEquipmentFindingStatusOptions = () => [
  { id: EquipmentFindingStatuses.Open, name: EquipmentFindingStatuses.Open },
  { id: EquipmentFindingStatuses.Closed, name: EquipmentFindingStatuses.Closed }
];

export const getRatingFilters = () => [
  { id: Rating.CRITICAL, label: 'Critical', className: 'sc-select-option-badge ion-color-danger' },
  { id: Rating.WARNING, label: 'Warning', className: 'sc-select-option-badge ion-color-warning' },
  { id: Rating.UNDEFINED, label: 'Undefined', className: 'sc-select-option-badge ion-color-medium' },
  { id: Rating.OK, label: 'OK', className: 'sc-select-option-badge ion-color-success' },
  { id: Rating.NON_SAFETY, label: 'Non-safety', className: 'sc-select-option-badge ion-color-secondary' }
];

export const getDefaultDateRangeFilter = () => ({
  id: '4',
  label: 'Last 24 hours',
  unit: 'hour',
  amount: -24,
  uriKey: '24h'
});

export const getDateRangeFilterOptions = () => [
  { id: '1', label: 'Last 30 minutes', unit: 'minute', amount: -30, uriKey: '30min' },
  { id: '2', label: 'Last hour', unit: 'hour', amount: -1, uriKey: '1h' },
  { id: '3', label: 'Last 4 hours', unit: 'hour', amount: -4, uriKey: '4h' },
  { id: '4', label: 'Last 24 hours', unit: 'hour', amount: -24, uriKey: '24h' },
  { id: '5', label: 'Last 7 days', unit: 'day', amount: -7, uriKey: '7days' },
  { id: '6', label: 'Last 30 days', unit: 'day', amount: -30, uriKey: '30days' },
  { id: '7', label: 'Last 60 days', unit: 'day', amount: -60, uriKey: '60days' },
  { id: '8', label: 'Last 1 year', unit: 'year', amount: -1, uriKey: '1year' }
];

const getInitialAppliedFilters = () => ({
  dateRange: getDefaultDateRangeFilter(),
  location: null,
  rating: [],
  topConcern: [],
  processName: [],
  eventType: [],
  mainFunction: [],
  equipmentFindingStatus: null
});

const getInitialTrendsState = () => ({
  currentPeriod: 'weekly',
  periodOptions: ['weekly', 'monthly']
});

const getInitialState = (): EventState => ({
  events: [],
  barrierEvents: [],
  actions: [],
  locations: [],
  showAllLocationsQuickOption: true,
  barriers: [],
  vesselPositions: [],
  appliedFilters: getInitialAppliedFilters(),
  mainFunctions: [],
  eventPageSegment: EventPageSegments.List,
  filters: {
    allowedBarrierRatingFilters: getAllowedBarrierViewRatingFilters(),
    rating: getRatingFilters(),
    equipmentFindingStatus: getEquipmentFindingStatusOptions(),
    processName: [],
    eventType: []
  },
  trends: getInitialTrendsState(),
  showMap: false,
  showTrends: false,
  loading: false
});

const eventReducer = (state: EventState = getInitialState(), action: EventActionTypes) => {
  switch (action.type) {
    case EventActions.LOAD_EVENTS_BEGIN: {
      return {
        ...state,
        loading: true
      };
    }

    case EventActions.LOAD_EVENTS_SUCCESS: {
      const filters = action.payload.filters;
      const appliedFilters = state.appliedFilters;
      const composedFilterKeys = getComposedFilterKeys();
      for (const filterKey of composedFilterKeys) {
        if (appliedFilters[filterKey].length === 0) {
          continue;
        }

        const selectedFilterIds = appliedFilters[filterKey].map(r => r.id.toLowerCase());
        appliedFilters[filterKey] = filters[filterKey].filter(r => selectedFilterIds.includes(r.id.toLowerCase()));
      }

      return {
        ...state,
        loading: false,
        events: action.payload.events,
        appliedFilters: {
          ...appliedFilters
        },
        filters: {
          ...state.filters,
          ...filters
        }
      };
    }

    case EventActions.LOAD_EVENT_SUCCESS: {
      const newEvents = [...state.events, action.payload.event];
      return {
        ...state,
        events: newEvents,
        barrierEvents: [],
        filters: {
          ...state.filters,
          ...action.payload.filters
        }
      };
    }

    case EventActions.LOAD_EVENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    }

    case EventActions.ADD_EVENT: {
      const newEvents = [action.payload.event, ...state.events];
      const composedFilters = composeFiltersFromEvents(newEvents);
      return {
        ...state,
        loading: false,
        events: newEvents,
        filters: {
          ...state.filters,
          ...composedFilters
        }
      };
    }

    case EventActions.UPDATE_EVENT: {
      const updatedEvent = action.payload.event;
      const eventIdx = state.events.findIndex(r => r.eventId === updatedEvent.eventId);
      const newEvents = [
        ...state.events.slice(0, eventIdx),
        {
          ...updatedEvent,
          isRead: state.events[eventIdx].isRead
        },
        ...state.events.slice(eventIdx + 1)
      ];
      const composedFilters = composeFiltersFromEvents(newEvents);

      return {
        ...state,
        events: newEvents,
        filters: {
          ...state.filters,
          ...composedFilters
        }
      };
    }

    case EventActions.LOAD_LOCATIONS_BEGIN: {
      return {
        ...state
      };
    }

    case EventActions.LOAD_LOCATIONS_SUCCESS: {
      return {
        ...state,
        locations: action.payload.locations
      };
    }

    case EventActions.LOAD_LOCATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    }

    case EventActions.LOAD_VESSEL_POSITIONS_BEGIN: {
      return {
        ...state
      };
    }

    case EventActions.LOAD_VESSEL_POSITIONS_SUCCESS: {
      return {
        ...state,
        vesselPositions: action.payload.vesselPositions
      };
    }

    case EventActions.LOAD_ACTIONS_SUCCESS: {
      return {
        ...state,
        actions: action.payload.actions
      };
    }

    case EventActions.UPDATE_VESSEL_POSITION: {
      return {
        ...state,
        vesselPositions: [...state.vesselPositions.filter(r => r.globalId !== action.payload.vesselPosition.globalId), action.payload.vesselPosition]
      };
    }

    case EventActions.DELETE_VESSEL_POSITION: {
      return {
        ...state,
        vesselPositions: [...state.vesselPositions.filter(r => r.globalId !== action.payload.globalId)]
      };
    }

    case EventActions.TOGGLE_SHOW_MAP: {
      return {
        ...state,
        showMap: !state.showMap
      };
    }

    case EventActions.SET_SHOW_MAP: {
      return {
        ...state,
        showMap: action.payload.showMap
      };
    }

    case EventActions.TOGGLE_SHOW_TRENDS: {
      return {
        ...state,
        showTrends: !state.showTrends
      };
    }

    case EventActions.RESET_APPLIED_FILTERS: {
      return {
        ...state,
        appliedFilters: getInitialAppliedFilters()
      };
    }

    case EventActions.SET_APPLIED_FILTERS: {
      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          ...action.payload.appliedFilters
        }
      };
    }

    case EventActions.REPLACE_APPLIED_FILTERS: {
      return {
        ...state,
        appliedFilters: {
          ...getInitialAppliedFilters(),
          ...action.payload.appliedFilters
        }
      };
    }

    case EventActions.MARK_EVENT_READ: {
      return {
        ...state,
        events: state.events.map(evt => (evt.eventId === action.payload.eventId ? { ...evt, isRead: true } : evt))
      };
    }

    case EventActions.SET_TRENDS_PERIOD: {
      return {
        ...state,
        trends: {
          ...state.trends,
          currentPeriod: action.payload.period
        }
      };
    }

    case EventActions.SET_BARRIERS: {
      return {
        ...state,
        barriers: action.payload.barriers
      };
    }

    case EventActions.SET_BARRIER_EVENTS: {
      return {
        ...state,
        events: [],
        barrierEvents: action.payload.barrierEvents
      };
    }

    case EventActions.UPDATE_BARRIER_EVENT: {
      const barrierEvent = action.payload.barrierEvent;
      const evtIdx = state.barrierEvents.findIndex(
        r =>
          r.locationId === barrierEvent.locationId &&
          r.barrier.code === barrierEvent.barrier.code &&
          r.topConcern.code === barrierEvent.topConcern.code &&
          r.function.code === barrierEvent.function.code &&
          r.element.code === barrierEvent.element.code
      );

      const newState = { ...state };
      const allowedBarrierViewRatingFilters = getAllowedBarrierViewRatingFilters();

      if (evtIdx === -1 && allowedBarrierViewRatingFilters.includes(barrierEvent.rating)) {
        newState.barrierEvents.push(barrierEvent);
      } else if (evtIdx > -1) {
        newState.barrierEvents = [...state.barrierEvents.slice(0, evtIdx)];

        if (allowedBarrierViewRatingFilters.includes(barrierEvent.rating)) {
          newState.barrierEvents.push(barrierEvent);
        }

        newState.barrierEvents.push(...state.barrierEvents.slice(evtIdx + 1));
      }

      return newState;
    }

    case EventActions.SET_SHOW_ALL_LOCATIONS_QUICK_OPTION: {
      return {
        ...state,
        showAllLocationsQuickOption: action.payload.showAllLocationsQuickOption
      };
    }

    case EventActions.SET_MAIN_FUNCTION_OPTIONS: {
      return {
        ...state,
        mainFunctions: action.payload.mainFunctionShortList
      };
    }

    case EventActions.SET_EVENT_PAGE_SEGMENT: {
      return {
        ...state,
        eventPageSegment: action.payload.eventPageSegment
      };
    }

    default:
      return state;
  }
};

const getFilteredEvents = (events: MaritimeEvent[], appliedFilters): MaritimeEvent[] => {
  const eventTypeIds = appliedFilters.eventType.map(r => r.id);
  const processIds = appliedFilters.processName.map(r => r.id);

  return events.filter(event => {
    let matchesFilters = true;

    if (eventTypeIds.length) {
      matchesFilters &&= eventTypeIds.includes(event.eventType);
    }
    if (processIds.length) {
      matchesFilters &&= processIds.includes(event.processName);
    }

    return matchesFilters;
  });
};

export const maritimeEventMatchesFilters = (event: MaritimeEvent, appliedFilters: any): boolean => {
  const ratingIds = appliedFilters.rating.map(r => r.id);
  const { from, to } = getTimeFilters(appliedFilters.dateRange);
  let matchesFilters = true;
  if (from) {
    matchesFilters &&= new Date(event.timeUtc) >= from;
  }

  if (to) {
    matchesFilters &&= new Date(event.timeUtc) <= to;
  }

  if (ratingIds.length) {
    matchesFilters &&= ratingIds.includes(event.rating);
  }

  if (appliedFilters.location) {
    matchesFilters &&= (location as any).globalId === event.locationId;
  }

  return matchesFilters;
};

export const barrierEventMatchesFilters = (event: MaritimeEvent, appliedFilters: any): boolean => {
  const topConcernCodes = appliedFilters.topConcern.map(r => r.code);
  const ratingIds = appliedFilters.rating.map(r => r.id);

  let matchesFilters = true;
  if (topConcernCodes.length) {
    matchesFilters &&= topConcernCodes.includes(event.topConcern.code);
  }

  if (ratingIds.length) {
    matchesFilters &&= ratingIds.includes(event.rating);
  }

  if (appliedFilters.location) {
    matchesFilters &&= (location as any).globalId === event.locationId;
  }

  return matchesFilters;
};

// SELECTORS
export function getFilteredMaritimeEvents(state) {
  const { events, appliedFilters } = state.eventReducer;
  return getFilteredEvents(events, appliedFilters);
}

export function getFilteredBarrierEvents(state) {
  const { barrierEvents, appliedFilters } = state.eventReducer;
  const filteredBarrierEvents = getFilteredEvents(barrierEvents, appliedFilters);
  filteredBarrierEvents.sort((e1: MaritimeEvent, e2: MaritimeEvent) => e2.rating - e1.rating || e2.timeUtc.localeCompare(e1.timeUtc));
  return filteredBarrierEvents;
}

export function areBarrierEventsVisible(state) {
  const { appliedFilters } = state.eventReducer;
  return appliedFilters.topConcern.length > 0;
}

export function getFilteredVesselPositions(state) {
  const { appliedFilters, vesselPositions } = state.eventReducer;
  const filteredLocationId = appliedFilters.location ? appliedFilters.location.globalId : null;
  return filteredLocationId ? vesselPositions.filter(r => r.globalId === filteredLocationId) : vesselPositions;
}

export function getTopConcerns(state): TopConcern[] {
	const {barriers} = state.eventReducer;
	return barriers.flatMap(r => r.topConcerns).filter(r => r.visibility !== TopConcernVisibility.Internal);
}

export const EventPageSegments = {
	List: 'List',
	Map: 'Map',
	Equipment: 'Equipment'
};

export default eventReducer;
