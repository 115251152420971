import {
  LoadEventsBeginAction,
  LoadEventsSuccessAction,
  LoadEventsFailureAction,
  ToggleShowMapAction,
  SetAppliedFiltersAction,
  ReplaceAppliedFiltersAction,
  ResetAppliedFiltersAction,
  LoadLocationsBeginAction,
  LoadLocationsSuccessAction,
  LoadLocationsFailureAction,
  SetShowMapAction,
  AddEventAction,
  LoadVesselPositionsBeginAction,
  LoadVesselPositionsSuccessAction,
  UpdateVesselPositionAction,
  DeleteVesselPositionAction,
  ToggleShowTrendsAction,
  MarkEventReadAction,
  SetTrendsPeriodAction,
  LoadActionSuccessAction,
  LoadEventSuccessAction,
  SetBarriersAction,
  SetBarrierEventsAction,
  UpdateBarrierEventAction,
  SetShowAllLocationsQuickOption,
  SetMainFunctionOptions,
  SetEventPageSegmentAction,
  UpdateEventAction
} from './actions';

export type EventActionTypes =
  | LoadEventsBeginAction
  | LoadEventsSuccessAction
  | LoadEventSuccessAction
  | LoadEventsFailureAction
  | LoadActionSuccessAction
  | AddEventAction
  | UpdateEventAction
  | LoadLocationsBeginAction
  | LoadLocationsSuccessAction
  | LoadLocationsFailureAction
  | LoadVesselPositionsBeginAction
  | LoadVesselPositionsSuccessAction
  | UpdateVesselPositionAction
  | DeleteVesselPositionAction
  | SetAppliedFiltersAction
  | ReplaceAppliedFiltersAction
  | ResetAppliedFiltersAction
  | ToggleShowMapAction
  | SetShowMapAction
  | ToggleShowTrendsAction
  | MarkEventReadAction
  | SetTrendsPeriodAction
  | SetBarriersAction
  | SetBarrierEventsAction
  | UpdateBarrierEventAction
  | SetShowAllLocationsQuickOption
  | SetMainFunctionOptions
  | SetEventPageSegmentAction;

export enum EventActions {
  LOAD_EVENTS_BEGIN = 'LOAD_EVENTS_BEGIN',
  LOAD_EVENTS_SUCCESS = 'LOAD_EVENTS_SUCCESS',
  LOAD_EVENTS_FAILURE = 'LOAD_EVENTS_FAILURE',
  LOAD_EVENT_SUCCESS = 'LOAD_EVENT_SUCCESS',
  LOAD_ACTIONS_SUCCESS = 'LOAD_ACTIONS_SUCCESS',
  ADD_EVENT = 'ADD_EVENT',
  UPDATE_EVENT = 'UPDATE_EVENT',
  LOAD_LOCATIONS_BEGIN = 'LOAD_LOCATIONS_BEGIN',
  LOAD_LOCATIONS_SUCCESS = 'LOAD_LOCATIONS_SUCCESS',
  LOAD_LOCATIONS_FAILURE = 'LOAD_LOCATIONS_FAILURE',
  LOAD_VESSEL_POSITIONS_BEGIN = 'LOAD_VESSEL_POSITIONS_BEGIN',
  LOAD_VESSEL_POSITIONS_SUCCESS = 'LOAD_VESSEL_POSITIONS_SUCCESS',
  UPDATE_VESSEL_POSITION = 'UPDATE_VESSEL_POSITION',
  DELETE_VESSEL_POSITION = 'DELETE_VESSEL_POSITION',
  SET_APPLIED_FILTERS = 'SET_APPLIED_FILTERS',
  REPLACE_APPLIED_FILTERS = 'REPLACE_APPLIED_FILTERS',
  RESET_APPLIED_FILTERS = 'RESET_APPLIED_FILTERS',
  TOGGLE_SHOW_MAP = 'TOGGLE_SHOW_MAP',
  SET_SHOW_MAP = 'SET_SHOW_MAP',
  TOGGLE_SHOW_TRENDS = 'TOGGLE_SHOW_TRENDS',
  SET_SHOW_ALL_LOCATIONS_QUICK_OPTION = 'SET_SHOW_ALL_LOCATIONS_QUICK_OPTION',
  MARK_EVENT_READ = 'MARK_EVENT_READ',
  SET_TRENDS_PERIOD = 'SET_TRENDS_PERIOD',
  SET_BARRIERS = 'SET_BARRIERS',
  SET_BARRIER_EVENTS = 'SET_BARRIER_EVENTS',
  UPDATE_BARRIER_EVENT = 'UPDATE_BARRIER_EVENT',
  SET_MAIN_FUNCTION_OPTIONS = 'SET_MAIN_FUNCTION_OPTIONS',
  SET_EVENT_PAGE_SEGMENT = 'SET_EVENT_PAGE_SEGMENT'
}
