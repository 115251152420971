import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ExtendedMainFunctionDto, MainFunctionDto, MainFunctionShortDto } from '../types/equipment/mainFunctionDto';
import { VesselPositionDto } from '../types/vesselPosition/vesselPosition';
import { VesselPositionFilter } from '../types/vesselPosition/vesselPositionFilter';
import { ActionDto } from '../types/actions/action';
import { StreamDto } from '../types/event/stream';
import { GeoZoneDto } from '../types/geoZones/geoZone';
import { LocationDto } from '../types/locations/locationDto';
import { MaritimeEvent } from '../types/event';
import { EventFilter } from '../types/event/eventFilter';
import { EventReadDto } from '../types/event/eventReadDto';
import { BaseResult, ExtendedBaseResult } from '../types/common/base-result';
import StatisticsEntry from '../types/statistics/statisticsEntry';
import { UserDto } from '../types/auth/userDto';
import { SubscriptionDto } from '../types/notifications/subscriptionDto';
import { EventRatingStatisticsFilter } from '../types/event/eventRatingStatisticsFilter';
import { EventRatingStatisticsDto } from '../types/event/eventRatingStatisticsDto';
import { EventActionRequestDto } from '../types/event/eventActionRequestDto';
import { Barrier } from '../types/barrier';
import { BarrierEventFilter } from '../types/event/barrierEventFilter';
import { store } from '@stencil/redux';
import { setUser } from '../actions/auth/actions';
import { getRedirectUrlToLogin } from '../helpers/routerHelper';

let _httpClient: AxiosInstance = null;
const _apiClient: ApiClient = null;

export const createHttpClient = async (): Promise<AxiosInstance> => {
  if (_httpClient) return _httpClient;

  const config: AxiosRequestConfig = {
    baseURL: process.env.APP_BASE_URL,
    withCredentials: true,
    paramsSerializer: { indexes: null }
  };

  const unauthorizedExcludedPaths = ['/login', '/logout'];
  _httpClient = axios.create(config);
  _httpClient.interceptors.response.use(
    response => response,
    async error => {
      if (error.response.status === 401) {
        const rootStore = store.getStore();
        rootStore.dispatch(setUser(null) as any);
        if (!unauthorizedExcludedPaths.includes(window.location.pathname)) {
          window.location.href = getRedirectUrlToLogin();
        }
      }
      return error.response;
    }
  );

  return _httpClient;
};

const createApiClient = async (): Promise<ApiClient> => {
  if (_apiClient) return _apiClient;

  const httpClient = await createHttpClient();

  return {
    eventApi: {
      getEventsByFilter: async (filter: EventFilter): Promise<MaritimeEvent[]> => {
        const response = await httpClient.get<MaritimeEvent[]>('/api/events/extended', { params: filter });
        return response.data;
      },
      getBarrierEventsByFilter: async (filter: BarrierEventFilter): Promise<MaritimeEvent[]> => {
        const response = await httpClient.get<MaritimeEvent[]>('/api/events/barrier', { params: filter });
        return response.data;
      },
      getEvent: async (eventId: string): Promise<MaritimeEvent> => {
        const response = await httpClient.get<MaritimeEvent>(`/api/events/${eventId}`);
        return response.data;
      },
      getStream: async (streamId: string): Promise<StreamDto> => {
        const response = await httpClient.get<StreamDto>(`/api/events/stream/${streamId}`);
        return response.data;
      },
      setEventRead: async (eventReadDto: EventReadDto): Promise<BaseResult> => {
        const response = await httpClient.post<BaseResult>('/api/events/setRead', eventReadDto);
        return response.data;
      },
      getEventRatingStatistics: async (filter: EventRatingStatisticsFilter): Promise<EventRatingStatisticsDto[]> => {
        const response = await httpClient.post<EventRatingStatisticsDto[]>('/api/events/ratingStatistics', filter);
        return response.data;
      },
      requestAction: async (requestDto: EventActionRequestDto): Promise<ExtendedBaseResult<MaritimeEvent>> => {
        const response = await httpClient.post<ExtendedBaseResult<MaritimeEvent>>('/api/events/requestAction', requestDto);
        return response.data;
      },
      downloadEventsAsCsv: async (filter: EventFilter): Promise<void> => {
        const response = await httpClient.post<Blob>('/api/events/exportCsv', filter, { responseType: 'blob' });
        downloadBlob(response);
      }
    },
    barrierApi: {
      getAllBarriers: async (): Promise<Barrier[]> => {
        const response = await httpClient.get<Barrier[]>('/api/barrier/GetBarriers');
        return response.data;
      }
    },
    authApi: {
      getUser: async (): Promise<UserDto> => {
        const response = await httpClient.get<UserDto>('/api/auth/user');
        return response.data;
      }
    },
    actionApi: {
      getAllActions: async (): Promise<ActionDto[]> => {
        const response = await httpClient.get<ActionDto[]>('/api/actions');
        return response.data;
      }
    },
    equipmentApi: {
      getLocationMainFunctions: async (locationId: string): Promise<MainFunctionDto[]> => {
        const response = await httpClient.get<MainFunctionDto[]>(`/api/equipment/function/location/${locationId}`);
        return response.data;
      },
      getLocationExtendedMainFunctions: async (locationId: string): Promise<ExtendedMainFunctionDto[]> => {
        const response = await httpClient.get<ExtendedMainFunctionDto[]>(`/api/equipment/function/location/${locationId}/extended`);
        return response.data;
      },
      getAllMainFunctionsShort: async (): Promise<MainFunctionShortDto[]> => {
        const response = await httpClient.get<MainFunctionDto[]>('/api/equipment/function/short');
        return response.data;
      }
    },
    vesselPositionApi: {
      getVesselPositions: async (vesselPositionFilter: VesselPositionFilter): Promise<VesselPositionDto[]> => {
        const response = await httpClient.post<VesselPositionDto[]>('/api/vesselPositions', vesselPositionFilter);
        return response.data;
      }
    },
    geoZoneApi: {
      loadAllGeoZones: async (): Promise<GeoZoneDto[]> => {
        const response = await httpClient.get<GeoZoneDto[]>('/api/geozones');
        return response.data;
      }
    },
    locationApi: {
      loadLocations: async (): Promise<LocationDto[]> => {
        const response = await httpClient.get<LocationDto[]>('/api/locations/userLocations');
        return response.data;
      }
    },
    statisticsApi: {
      addEntry: async (entry: StatisticsEntry): Promise<BaseResult> => {
        const response = await httpClient.post<BaseResult>('/api/statistics/entry', entry);
        return response.data;
      }
    },
    notificationApi: {
      getVapidKey: async (): Promise<ExtendedBaseResult<string>> => {
        const response = await httpClient.get<ExtendedBaseResult<string>>('/api/notifications/vapid-key');
        return response.data;
      },
      registerSubscription: async (subscriptionDto: SubscriptionDto): Promise<ExtendedBaseResult<string>> => {
        const response = await httpClient.post<ExtendedBaseResult<string>>('/api/notifications/register-subscription', subscriptionDto);
        return response.data;
      }
    },
    configurationApi: {
      getConfiguration: async (): Promise<any> => {
        const response = await httpClient.get<any>('/api/configuration');
        return response.data;
      }
    },
    proxyApi: {
      getImageAsBlobResponse: async (url: string): Promise<AxiosResponse<Blob>> => {
        const blobResponse = await httpClient.get<Blob>(`/api/proxy/image/${encodeURIComponent(url)}`, { responseType: 'blob' });
        return blobResponse;
      }
    }
  };
};

const downloadBlob = (blobResponse: AxiosResponse<Blob>) => {
  const blobUrl = URL.createObjectURL(blobResponse.data);
  const link = document.createElement('a');
  link.href = blobUrl;

  function getFileNameFromResponseContentDisposition() {
    const parts = blobResponse.headers['content-disposition'].split(';');
    return parts[1].split('=')[1].replaceAll('"', '');
  }

  link.download = getFileNameFromResponseContentDisposition();
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const apiClient = await createApiClient();
export default apiClient;

export interface ApiClient {
  eventApi: EventApi;
  barrierApi: BarrierApi;
  authApi: AuthApi;
  actionApi: ActionApi;
  equipmentApi: EquipmentApi;
  vesselPositionApi: VesselPositionApi;
  geoZoneApi: GeoZoneApi;
  locationApi: LocationApi;
  statisticsApi: StatisticsApi;
  notificationApi: NotificationApi;
  configurationApi: ConfigurationApi;
  proxyApi: ProxyApi;
}

export interface EventApi {
  getEventsByFilter(filter: EventFilter): Promise<MaritimeEvent[]>;
  getBarrierEventsByFilter(filter: BarrierEventFilter): Promise<MaritimeEvent[]>;
  getEvent(eventId: string): Promise<MaritimeEvent>;
  getStream(streamId: string): Promise<StreamDto>;
  setEventRead(eventReadDto: EventReadDto): Promise<BaseResult>;
  getEventRatingStatistics(filter: EventRatingStatisticsFilter): Promise<EventRatingStatisticsDto[]>;
  requestAction(requestDto: EventActionRequestDto): Promise<ExtendedBaseResult<MaritimeEvent>>;
  downloadEventsAsCsv(filter: EventFilter): Promise<void>;
}

export interface BarrierApi {
  getAllBarriers(): Promise<Barrier[]>;
}

export interface AuthApi {
  getUser(): Promise<UserDto>;
}

export interface ActionApi {
  getAllActions(): Promise<ActionDto[]>;
}

export interface EquipmentApi {
  getLocationMainFunctions(locationId: string): Promise<MainFunctionDto[]>;
  getLocationExtendedMainFunctions(locationId: string): Promise<ExtendedMainFunctionDto[]>;
  getAllMainFunctionsShort(): Promise<MainFunctionShortDto[]>;
}

export interface GeoZoneApi {
  loadAllGeoZones(): Promise<GeoZoneDto[]>;
}

export interface VesselPositionApi {
  getVesselPositions(vesselPositionFilter: VesselPositionFilter): Promise<VesselPositionDto[]>;
}

export interface LocationApi {
  loadLocations(): Promise<LocationDto[]>;
}

export interface StatisticsApi {
  addEntry(entry: StatisticsEntry): Promise<BaseResult>;
}

export interface NotificationApi {
  getVapidKey(): Promise<ExtendedBaseResult<string>>;
  registerSubscription(subscriptionDto: SubscriptionDto): Promise<ExtendedBaseResult<string>>;
}

export interface ConfigurationApi {
  getConfiguration(): Promise<any>;
}

export interface ProxyApi {
  getImageAsBlobResponse(url: string): Promise<AxiosResponse<Blob>>;
}
